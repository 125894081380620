import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import { SiteTitleQuery } from "../../../graphql-types"

interface Props {
  title: string
  description: string
}

const siteTitleQuery = graphql`
  query SiteTitle {
    markdownRemark(fields: { slug: { eq: "site-informatie" } }) {
      frontmatter {
        site_title
      }
    }
  }
`

const Head: React.FC<Props> = ({ children, title, description }) => {
  const data: SiteTitleQuery = useStaticQuery(siteTitleQuery)

  return (
    <Helmet>
      <title>
        {title} | {data.markdownRemark.frontmatter.site_title}
      </title>
      <meta name="description" content={description} />

      {children}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />

      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://eyefashionleiden.nl/images/eyefashion-leiden-og.png"
      />
    </Helmet>
  )
}

export default Head
