import React, { CSSProperties } from "react"
import { Icon } from "@iconify/react"
import emailIcon from "@iconify/icons-carbon/email"
import telephoneIcon from "@iconify/icons-bi/telephone"
import hamburgerMenu from "@iconify/icons-cil/hamburger-menu"
import styled from "styled-components"

interface IconProps {
  color?: "white" | "grey" | "black"
  size?: "medium" | "big"
  onClick?: (e: MouseEvent) => void
  className?: string
  style?: CSSProperties
}

const normalIconSizes = {
  medium: {
    height: 21,
    width: 21,
  },
  big: {
    height: 30,
    width: 30,
  },
}

const biggerIconSizes = {
  medium: {
    height: 30,
    width: 30,
  },
  big: {
    height: 36,
    width: 36,
  },
}

const IconWrapper = styled<any>(Icon)`
  color: ${({ color, theme }) => theme[color] || color};
  height: ${({ dimensions }) => dimensions.height}px;
  width: ${({ dimensions }) => dimensions.width}px;
`

export const EmailIcon: React.FC<IconProps> = props => {
  const sizes = normalIconSizes

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={emailIcon}
    />
  )
}

export const TelephoneIcon: React.FC<IconProps> = props => {
  const sizes = normalIconSizes

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={telephoneIcon}
    />
  )
}

export const HamburgerIcon: React.FC<IconProps> = props => {
  const sizes = biggerIconSizes

  return (
    <IconWrapper
      dimensions={sizes[props.size || "medium"]}
      {...props}
      icon={hamburgerMenu}
    />
  )
}
