import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Flex, Box } from "ticeboxes"
import { H4, P } from "./Typography"
import { OpeningTimesQuery } from "../../../graphql-types"

interface Props {}

const query = graphql`
  query OpeningTimes {
    markdownRemark(fields: { slug: { eq: "openingstijden" } }) {
      frontmatter {
        is_open_monday
        is_open_tuesday
        is_open_wednesday
        is_open_thursday
        is_open_friday
        is_open_saturday
        is_open_sunday
        opening_monday
        opening_tuesday
        opening_wednesday
        opening_thursday
        opening_friday
        opening_saturday
        opening_sunday
      }
    }
  }
`

const OpeningTimes: React.FC<Props> = () => {
  const data: OpeningTimesQuery = useStaticQuery(query)

  const {
    is_open_monday,
    is_open_tuesday,
    is_open_wednesday,
    is_open_thursday,
    is_open_friday,
    is_open_saturday,
    is_open_sunday,
    opening_monday,
    opening_tuesday,
    opening_wednesday,
    opening_thursday,
    opening_friday,
    opening_saturday,
    opening_sunday,
  } = data.markdownRemark.frontmatter

  return (
    <>
      <H4 className="mb-4 text-center text-md-left" weight="normal" upcase>
        Openingstijden
      </H4>

      <Flex justify-content="center" justify-content-md-start>
        <Box>
          <Flex style={{ width: 232 }}>
            <P className="flex-grow-1">Maandag</P>
            <P>{is_open_monday ? opening_monday : "Gesloten"}</P>
          </Flex>
          <Flex style={{ width: 232 }}>
            <P className="flex-grow-1">Dinsdag</P>
            <P>{is_open_tuesday ? opening_tuesday : "Gesloten"}</P>
          </Flex>
          <Flex style={{ width: 232 }}>
            <P className="flex-grow-1">Woensdag</P>
            <P>{is_open_wednesday ? opening_wednesday : "Gesloten"}</P>
          </Flex>
          <Flex style={{ width: 232 }}>
            <P className="flex-grow-1">Donderdag</P>
            <P>{is_open_thursday ? opening_thursday : "Gesloten"}</P>
          </Flex>
          <Flex style={{ width: 232 }}>
            <P className="flex-grow-1">Vrijdag</P>
            <P>{is_open_friday ? opening_friday : "Gesloten"}</P>
          </Flex>
          <Flex style={{ width: 232 }}>
            <P className="flex-grow-1">Zaterdag</P>
            <P>{is_open_saturday ? opening_saturday : "Gesloten"}</P>
          </Flex>
          <Flex style={{ width: 232 }}>
            <P className="flex-grow-1">Zondag</P>
            <P>{is_open_sunday ? opening_sunday : "Gesloten"}</P>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

export default OpeningTimes
