import { DefaultTheme } from "styled-components"

const theme: DefaultTheme = {
  black: "#000",
  white: "#FFFFFF",
  breakPointSm: 576,
  breakPointMd: 768,
  breakPointLg: 992,
  breakPointXl: 1200,
}

export default theme
