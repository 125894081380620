import styled, { css } from "styled-components"
import { Link } from "gatsby"

const ButtonStyles = css`
  background-color: black;
  color: white;
  font-weight: 600;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  padding: 5px 20px;
  border-radius: 5px;
  transition: transform 100ms ease-in-out;

  &:hover {
    transform: scale(1.02);
    color: white;
  }
  &:active {
    transform: scale(0.99);
  }
`

const Button = styled.button`
  ${ButtonStyles}
`

export const ButtonLink = styled(Link)`
  display: inline-block;
  ${ButtonStyles}
`

export default Button
