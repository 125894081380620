import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { AfspraakLinkQuery } from "../../../graphql-types"
import { ButtonLink } from "./Button"

interface Props {
  text: string
}

const afspraakLinkQuery = graphql`
  query AfspraakLink {
    markdownRemark(fields: { slug: { eq: "site-informatie" } }) {
      frontmatter {
        afspraak_link
      }
    }
  }
`

const AfspraakButton: React.FC<Props> = ({ text }) => {
  const data: AfspraakLinkQuery = useStaticQuery(afspraakLinkQuery)

  return (
    <ButtonLink
      to={data.markdownRemark.frontmatter.afspraak_link}
      target="_blank"
    >
      {text}
    </ButtonLink>
  )
}

export default AfspraakButton
