import React, { useContext } from "react"
import { NavContext } from "../../contexts/NavContext"
import styled, { useTheme } from "styled-components"
import { HamburgerIcon } from "../general/Icon"
import Logo from "../general/images/Logo"
import { Link } from "gatsby"
import zIndexLayers from "../../utils/zIndexLayers"
import useViewPort from "../../hooks/useViewport"
import DesktopNav from "./DesktopNav"
import { Flex } from "ticeboxes"

const StyledHeader = styled.header<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: ${({ height }) => height}px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  z-index: ${zIndexLayers.header};
`

const LogoSection = styled.div`
  position: relative;
  left: -20px;
  width: 20%;
  @media (min-width: ${({ theme }) => theme.breakPointLg}px) {
    width: 30%;
  }
`

const Header: React.FC<{}> = () => {
  const { breakPointMd } = useTheme()
  const { width } = useViewPort()

  const { openNav, headerHeight } = useContext(NavContext)

  return (
    <>
      <StyledHeader height={headerHeight}>
        <LogoSection>
          <Link to="/">
            <Logo />
          </Link>
        </LogoSection>
        <Flex align-items="center" flex-grow-1 justify-content="between">
          {width > breakPointMd ? <DesktopNav /> : null}
          {width <= breakPointMd ? (
            <>
              <div></div>
              <HamburgerIcon style={{ cursor: "pointer" }} onClick={openNav} />
            </>
          ) : null}
        </Flex>
      </StyledHeader>
      <div style={{ height: headerHeight }}></div>
    </>
  )
}

export default Header
