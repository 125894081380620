import React from "react"
import { P } from "../general/Typography"
import MobileNavWrapper from "./MobileNavWrapper"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Box, Flex } from "ticeboxes"
import { TelephoneIcon } from "../general/Icon"
import { PhoneQuery } from "../../../graphql-types"
import AfspraakButton from "../general/AfspraakButton"

interface Props { }

const phoneQuery = graphql`
  query Phone {
    markdownRemark(fields: { slug: { eq: "site-informatie" } }) {
      frontmatter {
        phone
      }
    }
  }
`

const MobileNavList: React.FC<Props> = () => {
  const data: PhoneQuery = useStaticQuery(phoneQuery)

  const strippedPhone = data.markdownRemark.frontmatter.phone.replace("-", "")
  return (
    <MobileNavWrapper>
      <Flex px="4" py="4" py-md="5" flex-column h="100">
        <Box flex-grow="1">
          <nav>
            <ul>
              <li>
                <Link to="/">
                  <P>Home</P>
                </Link>
              </li>
              <li>
                <Link to="/winkel/glazen">
                  <P>Glazen</P>
                </Link>
              </li>
              <li>
                <Link to="/winkel/brillen">
                  <P>Brillen</P>
                </Link>
              </li>
              <li>
                <Link to="/winkel/nachtlenzen">
                  <P>Nachtlenzen</P>
                </Link>
              </li>
              <li>
                <Link to="/winkel/contactlenzen">
                  <P>Contactlenzen</P>
                </Link>
              </li>
              <li>
                <Link to="/service">
                  <P>Service</P>
                </Link>
              </li>
              <li>
                <Link to="/contact">
                  <P>Contact</P>
                </Link>
              </li>
              <li>
                <Box mt="5">
                  <a href={`tel:${strippedPhone}`}>
                    <Flex align-items="center">
                      <Box mr="2">
                        <TelephoneIcon />
                      </Box>
                      <P className="mb-0">
                        <Link to={`tel:${strippedPhone}`}>
                          {data.markdownRemark.frontmatter.phone}
                        </Link>
                      </P>
                    </Flex>
                  </a>
                </Box>
              </li>
              <li>
                <Box mt="5">
                  <AfspraakButton text="Maak een afspraak" />
                </Box>
              </li>
            </ul>
          </nav>
        </Box>
      </Flex>
    </MobileNavWrapper>
  )
}

export default MobileNavList
