import React from "react"
import { Col, Container, Row } from "reactstrap"
import { Box } from "ticeboxes"
import ContactSection from "../contact/ContactSection"
import OpeningTimes from "../general/OpeningTimes"
import StoreMenu from "../general/StoreMenu"
import { P } from "../general/Typography"

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <footer id="footer" style={{ backgroundColor: "#F6F6F6" }}>
      <Container>
        <Row>
          <Col xs="12" md="4">
            <Box text-center text-md-left my="5">
              <StoreMenu />
            </Box>
          </Col>
          <Col xs="12" md="4">
            <Box my="5">
              <OpeningTimes />
            </Box>
          </Col>

          <Col xs="12" md={{ size: 3, offset: 1 }}>
            <Box my="5" text-center text-md-left>
              <ContactSection />
              <P className="mb-4">© Eyefashion Leiden</P>
              <P>
                <a href="https://ticekralt.com">Site made by Tice Kralt</a>
              </P>
            </Box>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
