import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import zIndexLayers from "../../utils/zIndexLayers"
import TryOnIcon from "../general/TryOnIcon"
import { P } from "../general/Typography"

const TabContainer = styled.aside`
  position: fixed;
  z-index: ${zIndexLayers.tryOnTab};
  right: -152px;
  top: 30vh;
  padding: 5px 3px;
  border-radius: 5px 0 0 5px;
  box-shadow: -1px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  transition: right 200ms cubic-bezier(0.07, 0.62, 0.3, 0.96);
  &:hover {
    right: 0;
    svg {
      margin-left: 4px;
    }
  }
`

const StyledTryOnIcon = styled(TryOnIcon)`
  @media (min-width: ${({ theme }) => theme.breakPointMd}px) {
    height: 35px;
    width: 35px;
  }
`

interface Props {}

const TryOnSideTab: React.FC<Props> = () => {
  return (
    <Link to="/online-bril-passen">
      <TabContainer>
        <StyledTryOnIcon />
        <P className="m-0 mx-2">Online je bril passen</P>
      </TabContainer>
    </Link>
  )
}

export default TryOnSideTab
