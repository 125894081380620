import React from "react"
import styled, { css } from "styled-components"

type TextProps = {
  style?: React.CSSProperties
  className?: string
  upcase?: boolean
  weight?: "lighter" | "light" | "normal" | "bold"
  fontSize?: "small" | "medium" | "large"
}

const fontWeights = {
  lighter: 200,
  light: 300,
  normal: 400,
  bold: 600,
}

const fontSizes = {
  small: {
    xs: 20,
    md: 26,
  },
  medium: {
    xs: 28,
    md: 34,
  },
  large: {
    xs: 36,
    md: 46,
  },
}

const baseTextStyles = css<TextProps>`
  font-weight: ${({ weight }) => fontWeights[weight || "lighter"]};
  ${({ fontSize }) =>
    fontSize ? `font-size: ${fontSizes[fontSize].xs}px!important;` : ""}
  ${({ upcase }) => (upcase ? "text-transform: uppercase;" : "")}
  ${({ upcase }) => (upcase ? "letter-spacing: 1.8px;" : "")}
    @media (min-width: ${({ theme }) => theme.breakPointMd}px) {
    ${({ fontSize }) =>
      fontSize ? `font-size: ${fontSizes[fontSize].md}px!important;` : ""}
  }
`

const basePStyles = css<TextProps>`
  font-weight: ${({ weight }) =>
    `${fontWeights[weight || "normal"]}!important`};
`

export const P = styled.p<TextProps>`
  ${baseTextStyles}
  ${basePStyles}
`

export const H1 = styled.h1<TextProps>`
  ${baseTextStyles}
  letter-spacing: 4px;
  @media (min-width: ${({ theme }) => theme.breakPointMd}px) {
    font-size: 4em;
  }
`

export const H2 = styled.h2<TextProps>`
  ${baseTextStyles}
  font-size: 1.8em;
  @media (min-width: ${({ theme }) => theme.breakPointMd}px) {
    font-size: 2.4em;
  }
`

export const H4 = styled.h4<TextProps>`
  ${baseTextStyles}
`

export const Span = styled.span<TextProps>`
  ${baseTextStyles}
  ${basePStyles}
`
