import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { H4, P } from "./Typography"
import { StoreMenuItemsQuery } from "../../../graphql-types"

interface Props {}

export const storeMenuItemsQuery = graphql`
  query StoreMenuItems {
    allMarkdownRemark(filter: { fields: { type: { eq: "_store" } } }) {
      edges {
        node {
          frontmatter {
            title
            is_active
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

const StoreMenu: React.FC<Props> = () => {
  const data: StoreMenuItemsQuery = useStaticQuery(storeMenuItemsQuery)

  return (
    <>
      <H4 className="mb-4" weight="normal" upcase>
        Winkel
      </H4>
      {data.allMarkdownRemark.edges.map(({ node }) => {
        const slug = node.frontmatter.is_active
          ? `/winkel/${node.fields.slug}`
          : "/binnenkort?goBack=true"

        return (
          <Link to={slug}>
            <P>{node.frontmatter.title}</P>
          </Link>
        )
      })}
    </>
  )
}

export default StoreMenu
