import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { H4, P } from "../general/Typography"
import { ContactQuery } from "../../../graphql-types"
import { EmailIcon, TelephoneIcon } from "../general/Icon"

interface Props {}

const contactQuery = graphql`
  query Contact {
    markdownRemark(fields: { slug: { eq: "site-informatie" } }) {
      frontmatter {
        phone
        email
        address_line_1
        address_line_2
      }
    }
  }
`

const ContactSection: React.FC<Props> = () => {
  const data: ContactQuery = useStaticQuery(contactQuery)

  const strippedPhone = data.markdownRemark.frontmatter.phone
    .replace("-", "")
    .replace(" ", "")

  return (
    <>
      <H4 className="mb-4" weight="normal" upcase>
        Contact
      </H4>
      <P className="mb-2">
        <a
          href="https://www.google.com/maps/place/Eye+Fashion+Opticien+Leiden/@52.1610019,4.4862688,17z/data=!4m5!3m4!1s0x0:0x9ee253db3a19b5b9!8m2!3d52.1609988!4d4.4884575"
          target="_blank"
        >
          {data.markdownRemark.frontmatter.address_line_1}
          <br />
          {data.markdownRemark.frontmatter.address_line_2}
        </a>
      </P>
      <P className="mb-2">
        <TelephoneIcon className="mr-3" />
        <a href={`tel:${strippedPhone}`}>
          {data.markdownRemark.frontmatter.phone}
        </a>
      </P>
      <P className="mb-5">
        <EmailIcon className="mr-3" />
        <a href={`mailto:${data.markdownRemark.frontmatter.email}`}>
          {data.markdownRemark.frontmatter.email}
        </a>
      </P>
    </>
  )
}

export default ContactSection
