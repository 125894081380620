import React from "react"

interface Props {
  size?: "small" | "medium"
}

const sizes = {
  small: {
    width: 112,
    height: 80,
  },
  medium: {
    width: 200,
    height: 150,
  },
}

const Logo: React.FC<Props> = ({ size = "small" }) => {
  const { width, height } = sizes[size]

  return (
    <img
      style={{ width, height }}
      src="/images/EyeFashion-R-logo.svg"
      alt="Eyefashion By Rami Leiden Logo"
    />
  )
}

export default Logo
