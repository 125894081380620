import { Link, useStaticQuery } from "gatsby"
import React from "react"
import { P } from "../general/Typography"
import { StoreMenuItemsQuery } from "../../../graphql-types"
import AfspraakButton from "../general/AfspraakButton"
import { storeMenuItemsQuery } from "../general/StoreMenu"

interface Props { }

const DesktopNav: React.FC<Props> = () => {
  const storeData: StoreMenuItemsQuery = useStaticQuery(storeMenuItemsQuery)

  return (
    <nav style={{ width: "100%" }}>
      <ul
        style={{ margin: "auto", width: "100%" }}
        className="d-flex align-items-center flex-grow-1 justify-content-between"
      >
        <li>
          <Link to="/">
            <P className="m-0">Home</P>
          </Link>
        </li>
        <li>
          <Link to="/winkel/glazen">
            <P className="m-0">Glazen</P>
          </Link>
        </li>
        <li>
          <Link to="/winkel/brillen">
            <P className="m-0">Brillen</P>
          </Link>
        </li>
        <li>
          <Link to="/winkel/nachtlenzen">
            <P className="m-0">Nachtlenzen</P>
          </Link>
        </li>
        <li>
          <Link to="/winkel/contactlenzen">
            <P className="m-0">Contactlenzen</P>
          </Link>
        </li>
        <li>
          <Link to="/service">
            <P className="m-0">Service</P>
          </Link>
        </li>
        <li>
          <Link to="/contact">
            <P className="m-0">Contact</P>
          </Link>
        </li>
        <li>
          <AfspraakButton text="Maak een afspraak!" />
        </li>
      </ul>
    </nav>
  )
}

export default DesktopNav
